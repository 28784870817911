<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center">
        <div class="mb-3 md:mb-0">
          <div class="flex">
            <vs-button class="mr-3" color="primary" type="filled" icon-pack="feather" icon="icon-arrow-left" @click="$router.go(-1)"></vs-button>
            <vs-button color="primary" type="border" icon-pack="feather" icon="icon-refresh-cw" class="mr-3" @click="getData"/>
            <vs-button color="primary" :type="isEmptyFilter ? 'border' : 'filled'" icon-pack="feather" icon="icon-filter" class="px-3 mr-3" @click="modalFilter.active = true">Filter</vs-button>
            <vs-button color="primary" type="border" icon-pack="feather" icon="icon-plus" class="px-3" @click="modalAdd.active = true" :disabled="isDone || isLoading">Tambah</vs-button>
          </div>
        </div>
        <br>
        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <div class="w-full sm:w-auto flex">
            <!--search-->
            <vs-input icon-pack="feather" icon="icon-search" class="w-full" placeholder="Cari ID History" v-model="search"/>
          </div>
        </div>
      </div>

      <vs-divider class="mt-5"></vs-divider>
      <div class="flex items-center">
        <div class="w-40 h-40 mr-3 p-0 m-0">
          <vs-avatar :text="pekerjaan.no_urut.toString()" size="40px"/>
        </div>
        <div class="w-full">
          <h4 class="font-semibold">{{ pekerjaan.nama_item_progress_pekerjaan || 'Loading..' }}</h4>
          <p class="mt-1 opacity-75">Bobot {{ pekerjaan.bobot }}%</p>
        </div>
        <div class="flex" v-if="!isLoading">
          <vs-icon size="small" icon-pack="material-icons" :icon="isDone ? 'check' : 'hourglass_empty'" :color="isDone ? 'success' : 'warning'"/>
          <span class="font-medium" :class="isDone ? 'text-success' : 'text-warning'">{{ isDone ? 'Done' : 'WIP' }}</span>
        </div>
      </div>
    </vx-card>

    <!--loading-->
    <vs-progress v-if="isLoading" indeterminate color="primary" :height="1"/>

    <!--data-->
    <div v-if="!isLoading && pekerjaan.progress.length > 0" class="mt-3">
      <vs-collapse class="no-padding">
        <vs-collapse-item class="vx-card mb-2 p-2" v-for="item in rowsItems" :key="item.id">
          <div slot="header" class="flex justify-between mr-4 center">
            <div>
              <span class="font-bold text-xl" :class="['text-' + item.status_approval_color]">{{ item.progress }}%</span>
              <feather-icon :class="['text-' + item.status_approval_color]" icon="MinusIcon" svgClasses="h-4 w-4" class="mx-2"/>
              <span class="text-sm font-medium" :class="['text-' + item.status_approval_color]">#ID{{ item.id }}</span>
            </div>
            <div>
              <span class="whitespace-no-wrap py-1 px-2 font-semibold text-xs leading-none text-white rounded-full" :class="['bg-' + item.status_approval_color]">{{ item.status_approval }}</span>
            </div>
          </div>

          <ul class="twitter-feeds-list">
            <li class="twitter-feed">
              <div class="twitter-feed-header flex items-center">
                <vs-avatar class="m-0" size="35px"/>
                <div class="leading-tight ml-3">
                  <p class="feed-author font-semibold"><span class="text-primary text-lg">{{ item.created_by_username }}</span> - {{ item.created_by_role }}</p>
                  <div class="flex items-center space-x-3">
                    <small class="inline-block text-primary text-xs">{{ item.created_at }}</small>
                    <small class="inline-block text-xs opacity-50" v-if="item.status_approval !== 'DITERIMA'">|</small>
                    <small class="inline-block text-danger text-xs underline" @click="confirmDelete(item.id)" v-if="item.status_approval !== 'DITERIMA'">Delete</small>
                  </div>
                </div>
              </div>
              <p class="mt-4"><span class="font-semibold">Pengerjaan:</span> {{ item.pekerjaan }}</p>
              <p class="mt-1"><span class="font-semibold">Keterangan:</span> {{ item.keterangan_kontraktor }}</p>
              <div class="flex space-x-2 mt-4">
                <vs-button type="border" size="small" icon-pack="feather" icon="icon-image" class="px-2" v-if="item.foto_kontraktor" @click="showImages(item.foto_kontraktor_url_list)">Lihat Foto</vs-button>
                <vs-button type="border" size="small" icon-pack="feather" icon="icon-file" class="px-2" v-if="item.file_kontraktor" @click="showFiles(item.file_kontraktor_url_list)">Lihat Files</vs-button>
              </div>
            </li>
            <vs-divider class="mt-5"></vs-divider>
            <li>
              <div class="flex space-x-2">
                <vs-button type="flat" size="small" icon-pack="feather" icon="icon-layers" class="px-2" @click="showModalApproval(item)">Approval Progress</vs-button>
                <vs-button type="flat" size="small" icon-pack="feather" icon="icon-image" class="px-2" v-if="item.foto_teknik" @click="showImages(item.foto_teknik_url_list)">Lihat Foto Approval</vs-button>
                <vs-button type="flat" size="small" icon-pack="feather" icon="icon-file" class="px-2" v-if="item.file_teknik" @click="showFiles(item.file_teknik_url_list)">Lihat Files Approval</vs-button>
              </div>
            </li>
          </ul>
        </vs-collapse-item>
      </vs-collapse>
    </div>

    <div v-if="!isLoading && pekerjaan.progress.length < 1" class="w-full mt-base">
      <p class="text-center w-full opacity-75">Tidak ada data.</p>
    </div>

    <!--modals-->
    <ProgressUnitFilter :isActive.sync="modalFilter.active" @filter="onFilter"/>
    <ProgressUnitAdd :isActive.sync="modalAdd.active" :itemSpkPekerjaan="pekerjaan" @success="getData"/>
    <ModalApprovalProgress :isActive.sync="modalApproval.active" :type="modalApproval.type" :id-ref="modalApproval.idRef"/>
    <FilesViewer :filesUrl="modalFiles.filesUrl" :isActive.sync="modalFiles.active"/>
    <ImageViewer ref="viewer"/>
  </div>
</template>

<script>
import SpkRepository from '@/repositories/proyek/spk-repository'
import ProgressUnitRepository from '@/repositories/proyek/progress-unit-repository'
import ImageViewer from '@/views/components/image-viewer/ImageViewer'
import _ from 'lodash'

export default {
  name: 'ProgressUnitHistory',
  props: {
    isRouteProgressUnit: { required: true, type: Boolean } // Progress Unit or Progress Proyek
  },
  components: {
    ImageViewer,
    ProgressUnitFilter: () => import('@/views/pages/proyek/progress-unit/ProgressUnitFilter'),
    ProgressUnitAdd: () => import('@/views/pages/proyek/progress-unit/ProgressUnitAdd'),
    FilesViewer: () => import('@/views/components/files-viewer/FilesViewer'),
    ModalApprovalProgress: () => import('@/views/components/approval-progress/ModalApprovalProgress')
  },
  mounted () {
    this.getData()
  },
  data () {
    return {
      isLoading: false,
      search: null,
      filter: {},
      modalApproval: {
        active: false,
        type: null,
        idRef: null
      },
      modalFilter: {
        active: false
      },
      modalAdd: {
        active: false
      },
      modalFiles: {
        filesUrl: [],
        active: false
      },
      pekerjaan: {
        no_urut: '#',
        progress: []
      }
    }
  },
  computed: {
    rowsItems () {
      const search = this.search
      const items = this.pekerjaan.progress
      if (search) {
        return _.filter(items, (item) => {
          return item.id.toString().toLowerCase().includes(search.toString().toLowerCase())
        })
      }
      return items
    },
    isDone () {
      return this.pekerjaan.progress_percentage >= 100
    },
    isEmptyFilter () {
      return _.isEmpty(this.filter)
    }
  },
  methods: {
    getData () {
      this.isLoading = true

      const idSpkPekerjaan = this.$route.params.idSpkPekerjaan
      const params = {}
      if (!this.isEmptyFilter) {
        params.filter = JSON.stringify(this.filter)
      }

      SpkRepository.showPekerjaan(idSpkPekerjaan, params)
        .then(response => {
          this.pekerjaan = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin hapus data?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    delete (id) {
      ProgressUnitRepository.delete(id)
        .then(response => {
          this.getData()
          this.notifyError('Berhasil menghapus data.')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    onFilter (filterData) {
      this.filter = filterData
      this.getData()
    },

    showImages (images) {
      const items = _.cloneDeep(images)
      this.$refs.viewer.show(items)
    },

    showFiles (files) {
      this.modalFiles.filesUrl = files
      this.modalFiles.active = true
    },

    showModalApproval (item) {
      this.modalApproval.type = 'SPP-PROGRESS'
      this.modalApproval.idRef = item.id
      this.modalApproval.active = true
    }
  }
}
</script>
